import React, { Component } from 'react';
import '../styles/landingpage.scss';



class Landingpage extends Component {
    constructor(props) {
        super(props);
    }

    scroll_to_chat = () => {
        const element = document.getElementById('communication');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

      scroll_to_infos = () => {
        const element = document.getElementById('infos');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    state = {  }
    render() { 
        return (
        <div className="landingpage">
            <div className='title'>
                <div id='headline'>BierGPT</div>
                <div id='sub-headline'>Die einzige KI, die du brau(ch)st!</div>
                <div id='shortcut-buttons'>
                <div className='shortcut-button' onClick={this.scroll_to_infos}>
                    <div className='shortcut-icon'>
                        <img src='machine-learning.png' />
                    </div>
                    <div className="shortcut-text">Funktionsweise</div>
                </div>
                <div className='shortcut-button' onClick={this.scroll_to_chat}>
                    <div className='shortcut-icon'>
                        <img src='beer.png' />
                    </div>
                    <div className="shortcut-text">Versuche es!</div>
                </div>
            </div>
            </div>
            <div id='more-indicator'>
                <span>&#8659;</span>
            </div>
        </div>
        );
    }
}
 
export default Landingpage;