import React, { Component } from 'react';
import '../styles/user-question.scss';


class UserQuestion extends Component {


    constructor(props) {
        super(props);
    }

    render() { 
        return (
            <div className='question'>
                {this.props.question}
            </div>
        );
    }
}
 
export default UserQuestion;