import './styles/App.scss';
import React, { Component } from 'react';
import Teaser from './components/teaser';
import Landingpage from './components/landingpage';
import Communication from './components/communication';

class App extends Component {


  constructor(props) {
    super(props);
  }



  render() { 
    return (
    <div className="App">
      <img id="image-bg" className='beer-background' src='background_beer.jpg'/>
      <div  dangerouslySetInnerHTML={{__html: '<video autoplay muted playsinline loop class="beer-background" src="background_vid_noaudio.mov"></video>'}} />
      <Landingpage />
      <Teaser />
      <Communication />
    </div>
  );
  }
}



export default App;
