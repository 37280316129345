import React, { Component } from 'react';
import SellingPoint from './selling-point';
import '../styles/teaser.scss';
class Teaser extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return (
            <div id="infos" className='infos'>
                <div className='teaser'>
                    <SellingPoint icon='idea.png' text='Erfrischende Lösungen für deine Probleme' />
                    <SellingPoint icon='monk.png' text='Jahrhunderte gesammelter Trainingsdaten' />
                    <SellingPoint icon='clock.png' text='HalBIERt deine Arbeitszeit (24/7 verfügBAR)' />
                </div>
                <div className='functionality'>
                    <div className='functionality-icon'>
                        <img src='machine-learning.png' />
                    </div>
                    <div className='functionality-text'>
                        Dein Gehirn ist ein riesiges neuronales Netz,
                        von dem du nur einen Bruchteil nutzt.
                        BierGPT hilft dir häufig benutzte Neuronen gezielt auszuschalten
                        und bisher nicht aktivierte Neuronen anzuregen.
                        Nutze gezielt die Vorteile der Dropout-Technik, um in
                        Job und Alltag besser generalisieren zu können und deine
                        Produktivität zu steigern.
                        <b> Nutze BierGPT!</b>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Teaser;