import React, { Component } from 'react';
import UserQuestion from './user-question';
import Answer from './answer';
import '../styles/communication.scss';

class Communication extends Component {

    async getBeers() {
        const res = await fetch("biere.txt");
        const data = await res.text();
        var lines = data.split('\n');
        return this.setState({ beers: lines });
      }

    async getZitate() {
        const res = await fetch("sprueche.txt");
        const data = await res.text();
        var lines = data.split('\n');
        return this.setState({ sprueche: lines });
    }
    
    componentDidMount() {
        this.getBeers();
        this.getZitate();
    }

    getBierorakelAnswer = () => {
        var schablonen = [
            "Trink doch ein TOKEN!",
            "Wie wärs mit einem TOKEN?",
            "Ich empfehle ein TOKEN!",
            "Ich würde ein TOKEN trinken!",
        ];
        var schablone = schablonen[Math.floor(Math.random() * schablonen.length)];
        var answer = schablone.replace("TOKEN", this.state.beers[Math.floor(Math.random() * this.state.beers.length)]);
        return answer;
    }

    getZitat = () => {
        var egal_schablone = [
            "Das spielt keine Rolle, einfach ein Bier trinken. ",
            "Egal, hau weg den Humpen. ",
            "Ein Krug ist Antwort genug. ",
            "Du suchst eine Antwort? Ein Bier ist die Antwort. ",
            "Hopfen und Malz, Gott erhalt's. Die Antwort ist klar. ",
            "Warum fragst du mich? Trink einfach ein Bier. ",
            "Ich bin mir nicht sicher, aber ein Bier hilft immer. ",
            "Wenn du mich fragst, dann zisch das Ding weg! ",
            "Ist das Bier leer, muss das Nächste her. ",
        ];
        var zitat = this.state.sprueche[Math.floor(Math.random() * this.state.sprueche.length)];
        var toReturn = zitat + " " + this.getBierorakelAnswer();
        //20 percent chance
        if (Math.random() < 0.5) {
            var schablone = egal_schablone[Math.floor(Math.random() * egal_schablone.length)];
            toReturn = schablone + this.getBierorakelAnswer();
        }
        return toReturn;
    }
        

    makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }

    addAnswer = (question) => {
        var new_id = this.makeid(10);
        var text_answer = "Beer";
        if ("bierorakel" === question.toLowerCase()) {
            text_answer = this.getBierorakelAnswer();
        } else {
            text_answer = this.getZitat();
        }
        var key = this.state.messages.length+this.state.answers.length + 1;
        const answer = <Answer answer={text_answer} id={new_id} key={key} />;
        this.setState({ answers: [answer, ...this.state.answers] }, () => {
            var objDiv = document.getElementById("dialog");
            objDiv.scrollTop = objDiv.scrollHeight;
                }
        );
    }
    
    addMessage = (event) => {
        event.preventDefault();
        const message = document.querySelector('.communication input').value;
        if (message === '') {
            return;
        }
        var key = this.state.messages.length+this.state.answers.length;
        const question = <UserQuestion question={message} key={key} />;
        this.setState({ messages: [question, ...this.state.messages] });
        document.querySelector('.communication input').value = '';
        this.addAnswer(message);
    }

    getInterleavedMessages = () => {
        const messages = this.state.messages;
        const answers = this.state.answers;
        const interleavedMessages = [];
        for (let i = messages.length-1; i >= 0; i--) {
            interleavedMessages.push(messages[i]);  
            interleavedMessages.push(answers[i]);
        }
        return interleavedMessages;
    }

    constructor(props) {
        super(props);
    }
    state = { 
        messages: [<UserQuestion question={"BierGPT, wie viel Bier soll ich heute trinken?"} key={5}/>, <UserQuestion question={"Was darf ich dich fragen?"} key={3} />, <UserQuestion question={"Hallo BierGPT! Wie geht es dir?"} key={1} />],
        answers: [<Answer answer={"Es spricht Gambrinus der Herr und König, trink mäßig, doch trink nie zu wenig!"} key={4}/>, <Answer answer={"Frag mich alles, was du möchtest, oder teste den Befehl 'Bierorakel'"} key={2}/>,<Answer answer={"Mir geht es gut! Wie geht es dir? Trink doch ein Bier!"} key={0}/>]
    }
    
    render() { 
        return (
        <div>
            <div id="dialog" className="chat-dialog">
                {this.getInterleavedMessages()}
            </div>
            <form onSubmit={this.addMessage}>
                <div id="communication" className='communication'>
                    <input type='text' placeholder='Frag mich alles, was du möchtest.' />
                    <button type='submit'>
                    <img src='beer.png' alt='send' />
                    </button>
                </div>
            </form>
        </div>
    );
    }
}
 
export default Communication;