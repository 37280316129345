import React, { Component } from 'react';
import '../styles/answer.scss';
import Typewriter from 'typewriter-effect';
import { type } from '@testing-library/user-event/dist/type';

class Answer extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    render() { 
        return (
        <div id={this.props.id} className='answer' ref={this.myRef}>
            {this.props.answer}
        </div> );
    }

    executeScroll = () => this.myRef.current.scrollIntoView();
}
 
export default Answer;