import React, { Component } from 'react';

import '../styles/sellingpoint.scss';


class SellingPoint extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return (
            <div className='selling-point'>
                <div className='icon'>
                    <img src={this.props.icon} />
                </div>
                <div className='text'>
                    {this.props.text}
                </div>
            </div>
                
        );
    }
}
 
export default SellingPoint;